/**
 * helpers.js
 * desc: utilities for internationalization of translated languages
 */

import { getQueryStringObject } from '../helpers/utils';

// Language Codes: Codes for the Representation of Names of Languages (ISO 639-1 Code)
// https://www.loc.gov/standards/iso639-2/php/code_list.php
// Some languages only have three letter codes (ISO 639-2 Code)
//
// Country Codes: Country ISO codes (ALPHA-2)
// https://www.iban.com/country-codes

const locales = {
  DEFAULT: "en-US", // Default: English US
  SUPPORTED: [
    "af-ZA",  // Afrikaans - South Africa
    "af",     // Afrikaans
    "ar-IL",  // Arabic - Israel
    "ar",     // Arabic
    "bg-BG",  // Bulgarian - Bulgaria
    "bg",     // Bulgarian
    // "bn-IN",  // Bengali - India
    // "bn",     // Bengali
    "ceb-PH", // Cebuano - Philippines
    "ceb",    // Cebuano
    "cs-CZ",  // Czech - Czech Republic
    "cs",     // Czech 
    "da-DK",  // Dutch - Denmark
    "da",     // Dutch
    // "de-AT",  // German - Austria
    "de-DE",  // German - Germany
    "de",     // German
    // "en-AU",  // English - Australia
    // "en-IN",  // English - India
    // "en-NZ",  // English - New Zealand
    "en-GB",  // English - United Kingdom
    "en-US",  // English - United States
    // "en-ZA",  // English - South Africa
    "en",     // English
    "es-ES",  // Spanish - Spain
    "es-US",  // Spanish - United States
    "es",     // Spanish
    "fi-FI",  // Finnish - Finland
    "fi",     // Finnish
    "fil-PH", // Filipino (Tagalog) - Philippines
    "fil",    // Filipino (Tagalog)
    "fr-BE",  // French - Belgium
    "fr-CA",  // French - Canada
    "fr-FR",  // French - France
    "fr",     // French
    "id-ID",  // Bahasa - Indonesia
    "id",     // Bahasa
    "it-IT",  // Italian - Italy
    "it",     // Italian
    "hi-IN",  // Hindi - India
    "hi",     // Hindi
    "he-IL",  // Hebrew - Israel
    "he",     // Hebrew
    "hil-PH", // Hiligaynon - Philippines
    "hil",    // Hiligaynon
    "hu-HU",  // Hungarian - Hungary
    "hu",     // Hungarian
    "ja-JP",  // Japanese [chrome uses JA] - Japan
    "ja",     // Japanese [chrome uses JA]
    "ko-KR",  // Korean - Korea (the Republic of)
    "ko",     // Korean
    "mr-IN",  // Marathi - India
    "mr",     // Marathi
    "ms-MY",  // Malay - Malaysia
    "ms",     // Malay
    "nl-BE",  // Dutch - Belgium
    "nl",     // Dutch
    "no-NO",  // Norwegian - Norway
    "no",     // Norwegian
    "pl-PL",  // Polish - Poland
    "pl",     // Polish
    "pt-BR",  // Portuguese - Brazil
    "pt",     // Portuguese
    "ro-RO",  // Romanian - Romania
    "ro",     // Romanian
    "ru-IL",  // Russian - Israel
    "ru",     // Russian
    // "sn",     // Shona
    "sr-RS",  // Serbian - Serbia
    "sr",     // Serbian
    "st-ZA",  // Sotho - South Africa
    "st",     // Sotho
    "sv-SE",  // Swedish - Sweden
    "sv",     // Swedish
    "ta-IN",   // Tamil - India
    "ta",     // Tamil
    "te-IN",  // Telugu - India
    "te",     // Telugu
    "tr-TR",  // Turkish - Turkey
    "tr",     // Turkish
    // "ur-IN",  // Urdu - India
    // "ur",     // Urdu
    "xh-ZA",  // Xhosa - South Africa
    "xh",     // Xhosa
    "zh-CN",  // Chinese - China
    "zh-US",  // Chinese - United States
    "zh",     // Chinese
    "zu-ZA",  // Zulu - South Africa
    "zu"      // Zulu
  ]
}

 /**
  * getSupportedDictionary
  * desc: returns available data file
  * @param  {string} locale   uses a validated locale string
  * @param  {string} path  relevant path of data
  * @return {obj}       locale object
  */
 export async function getSupportedDictionary(locale, path) {
  const language = locale.split('-')[0];
  let localeFile, languageFile, defaultFile;

  // Try to load local and language files
  try {
    localeFile = await import(`./locales/${path}/${locale}.json`);
    languageFile = await import(`./locales/${path}/${language}.json`);
  }
  catch (e) {
    if (e instanceof Error && e.code === "MODULE_NOT_FOUND") {
      console.log(e.message);
    } else {
      throw e;
    }
  }

  defaultFile = await import(`./locales/${path}/${locales.DEFAULT}.json`);

  if (!!localeFile) {
    return localeFile;
  } else if (!!languageFile) {
    return languageFile;
  } else {
    return defaultFile;
  }
}


/**
  * getLocale
  * desc: returns available locale
  * @return {string}       locale string
  */
export function getLocale() {
 const qsObj = getQueryStringObject();
 const userLocale = (qsObj.locale) ? qsObj.locale : navigator.language ? navigator.language : 'en-us';
 
 // check if user locale supported
 function localeFound(locale) {
   return locales.SUPPORTED.indexOf(locale) !== -1
 }
 const language = userLocale.split('-')[0];
 // Set locale to complete local, langauge, or default
 return localeFound(userLocale) ? userLocale : localeFound(language) ? language : locales.DEFAULT;
}
